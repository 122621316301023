@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

* {
  font-family: 'Quicksand', sans-serif;
}
/*scroll*/
::selection {
  background: #895ffc;
  color: #fff;
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: none;
}

::-webkit-scrollbar-track-piece {
  background-color: #dedede;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 0px;
  height: 20px;
  background-image: linear-gradient(#fbb03b, #c4272e);
}
/* Style the pagination dots */
.swiper-pagination-bullet {
  background-color: #bbb !important;
  border: 1px solid !important; /* Default dot color */
}

/* Style the active pagination dot */
.swiper-pagination-bullet-active {
  background-color: #fbb03b !important;
  border: none !important;
  /* Active dot color */
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
tbody,
tr,
td,
th,
span {
  font-family: 'Quicksand' !important;
}

a {
  text-decoration: none !important;
}

body {
  background-color: #eff5ee !important;
  overflow-x: hidden !important;
}

.mh-2 h2 {
  font-size: 33px;
  font-weight: 700;

  background: var(
    --New-Gradient,
    radial-gradient(
      292.31% 292.31% at -20.05% -138.79%,
      #fff 0%,
      #fbb03b 50%,
      #c4272e 100%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mh-3 h3 {
  color: #007341;
  font-size: 16px;
  font-weight: 700;
}

.bgText {
  background-position: center center;
  background-color: #464646;
  background-size: cover;
  background-clip: text;
  color: transparent;
}

.m3-h h3 {
  color: #1b1c1d;
  font-family: 'Quicksand', sans-serif;
  font-size: 32px;
}

.Ifiled input,
.Ifiled select,
.Ifiled textarea {
  padding: 26px 30px;
  border: 1px solid #b4b4b4;
  color: #1a1b1a;
}

.Ifiled input:focus,
.Ifiled select:focus,
.Ifiled textarea:focus {
  border-color: #424242;
  box-shadow: 0 0 0 1px #424242;
}
.bfiled input,
.bfiled select {
  padding: 0px 52px;
  border: 1px solid #409d46;
  height: 57px;
}
.bfiled input,
.bfiled select,
.bfiled textarea {
  padding: 10px 10px;
  border: 1px solid #409d46;
  color: #1a1b1a;
}

.bfiled input:focus,
.bfiled select:focus,
.bfiled textarea:focus {
  border-color: #424242;
  box-shadow: 0 0 0 1px #424242;
}

.ufiled input {
  padding: 50px;
  text-align: center;
  border: 1px dashed #424242;
}

.ufiled [type='file']::-webkit-file-upload-button {
  background: #75767a;
  border: 2px solid #75767a;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 10px 25px;
  text-transform: uppercase;
  transition: all 1s ease;
}

.ufiled [type='file'] {
  /* Style the color of the message that says 'No file chosen' */
  color: #878787;
  height: 90px;
  padding: 30px;
}

.subNavFirstDeg,
.subNavSecondDeg {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 50px;
  border-radius: 12px;
  transform: translateY(-10px);
  opacity: 1;
  height: 0px;
  overflow: auto;
}
.subNavSecondDeg {
  transform: translateX(-10px);
}

.subNavFirstDeg > div,
.subNavSecondDeg > div {
  display: flex;
  color: #000;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  gap: 25px;
}

.subNavFirstDeg > div svg,
.subNavSecondDeg > div svg {
  font-size: 20px;
}

.subNavSecondDeg {
  right: -340px;
  top: 60px;
  box-shadow: 5px 15px 28px -15px #000;
}

.hideFirstDeg {
  transition: 0.3s;
  transform: translate(0px);
  height: auto;
}

.hideSecondDeg {
  transition: 0.3s;
  transform: translate(0px);
  height: auto;
}
.orderTb td {
  padding: 10px 25px;
}

.Ifiled svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 9px;
  z-index: 1;
}

.Ifiled input,
.Ifiled select {
  padding: 0px 52px;
  border: 1px solid #409d46;
  height: 57px;
}
.Ifiled textarea {
  padding: 26px 52px;
  border: 1px solid #409d46;
}
.Ifiled input:focus,
.Ifiled select:focus,
.Ifiled textarea:focus {
  border-color: #409d46;
  box-shadow: 0 0 0 1px #409d46;
}
.Ifiled input:hover,
.Ifiled select:hover,
.Ifiled textarea:hover {
  border-color: #409d46;
  box-shadow: 0 0 0 1px #409d46;
}
.Ifiled button {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 1;
  background-color: transparent;
}
.Ifiled button:hover {
  background-color: transparent;
}
.Ifiled {
  position: relative;
}

/*media-quries*/
@media (max-width: 1440px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 1200px) {
}
@media (max-width: 980px) {
  .mh-2 h2 {
    font-size: 23px;
  }
}

@media (max-width: 768px) {
  .subNavFirstDeg {
    left: -50% !important;
  }
  .subNavSecondDeg {
    left: 45% !important;
  }
}

.chakra-avatar__excess {
  background-color: #399a48 !important;
  color: #fff !important;
}
.custom-change-design-phone {
  --react-international-phone-border-color: transparent;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  padding: 5px;
}
